.anonymization-table {
    font-size: 16px;
    text-align: left;
    table-layout: fixed;
    border-collapse: collapse; 
    width: 800px;
}

.anonymization-table-header {
    text-align: left;
}

.anonymization-table th {
    padding: 5px 5px;
}
.anonymization-table-delete-column {
    padding: 5px 5px;
    width: 26px;
}

.anonymization-table-cell {
    padding: 5px 5px;
    border: 1px solid var(--table-grey);
}

.anonymization-table-cell-tagname {
    width: 250px;
}

.anonymization-table-cell-fixed {
    color: var(--text-secondary)
}

.anonymization-table-input-cell {
    border: 1px solid var(--table-grey);
}

.anonymization-table-new-row {
    width: 20px;
}

.anonymization-table-new-row:hover {
    color: var(--text-secondary)
}

.anon-and-attachements-new-cell {
    border: none
}