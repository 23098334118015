.api-key-manager-table {
    font-size: 16px;
    text-align: left;
    table-layout: fixed;
    border-collapse: collapse; 
    width: 1000px;
    color: white;
    margin: auto;    
}

.api-key-manager-table-header {
    text-align: left;
}

.api-key-manager-key-column {
    width: 600px;
}

.api-key-manager-table th,td {
    padding: 5px 5px;
    border: 1px solid var(--table-grey);
}
.api-key-manager-secret-key {
    color: white;
    border: 1px solid white;
    width: 800px;
    margin: auto;
    padding: 20px 0px;
    background-color: var(--table-grey);
    border-radius: 10px;
}

.api-key-manager-button {
    border: 1px;
    border-radius: 4px;
    width: 120px;
    padding: 5px 5px;
    cursor: pointer;
    font-size: 12px;
    background-color: var(--background-green);
    color: var(--text-black);
}

.api-key-manager:hover {
    background-color: var(--background-green-highlight);
}