.file-dropzone {
    width: 320px;
    height: 50px;
    background-color: var(--background-grey-blue-dark);
    border: 1px solid white;
    border-radius: 25px;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.file-dropzone p {
    color: var(--text-primary);
    font-size: 12px;
}

.file-dropzone input {
    display: none
}

.file-dropzone-disabled {
    width: 320px;
    height: 50px;
    background-color: var(--table-grey);
    border-radius: 25px;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.file-dropzone-disabled p {
    color: var(--text-black);
    font-size: 12px;
}

.file-dropzone-disabled input {
    display: none
}