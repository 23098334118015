:root {
  --text-green: #16f0c4;
  --text-green-highlight: #60f3d5;
  --text-primary: #ffffff;
  --text-secondary: #91b9cd;
  --text-disabled: #595959;
  --text-black: #000000;
  --background-green: #16f0c4;
  --background-green-highlight: #60f3d5;
  --background-grey-blue-light: #91b9cd;
  --background-grey-blue: #507486;
  --background-grey-blue-dark: #2d3644;
  --background-grey-dark: #191818;
  --background-warning: #e29e4a;
  --input-deselected: #1f736d;
  --input-selected: #5ae6d9;
  --table-grey: #595959;
  --loading-blue: #0944b3;
  --loading-white: #ffffff;
  --red: #ff0000;
  --red-highlight: #f95858;
}


body {
  margin: 0;
  font-family: Lato, ui-sans-serif, sans-serif;
  font-weight: 300;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


h1 {
  color: var(--text-green);
  font-size: 32px;
  font-weight: 300;
}

h2 {
  color: var(--text-primary);
  font-size: 18px;
  font-weight: 300;
}

h3 {
  color: var(--text-primary);
  font-size: 24px;
  font-weight: 300;
}

p {
  color: var(--text-secondary);
}