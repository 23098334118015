.anonymization-table-row-delete {
    color: red;
    cursor: pointer;
    padding: 0px 10px;
    width: 16px;
    border: none;
}

.anonymization-table-row-delete:hover {
    color: rgb(255, 50, 50);
}

.anonymization-table-row-tooltip-icon {
    color: white;
    padding: 0px 10px;
    width: 16px;
}