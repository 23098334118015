.attachments-table {
    font-size: 16px;
    text-align: left;
    margin: 0 auto;
    border-collapse: collapse; 
}

.attachments-table-row {
    border: 1px solid var(--table-grey);
}

.attachments-table th {
    padding-left: 10px;
    padding-right: 10px;
}

.attachments-table td {
    padding-left: 10px;
    padding-right: 10px;
}

.attachments-table-filename {
    color: var(--text-primary);
    width: 500px;
}

.attachments-table-delete {
    cursor: pointer;
    color: var(--text-green)
}

.attachments-table-delete:hover {
    color: var(--text-green-highlight)
}