.guest-uploader-required-info-message {
    color: var(--text-primary)
}

.guest-uploader-required-info-input {
    width: 580px;
    margin: auto;
    display: grid;
    text-align: left;
    grid-template-columns: 120px 5px 35px 150px 210px;
    grid-template-rows: repeat(4, 20px);
    column-gap: 10px;
    row-gap: 10px;
    align-items: center;
}

.guest-uploader-required-info-input input {
    border-radius: 6px;
}

.guest-uploader-required-info-input p {
    color: var(--text-primary);
}

.required-info-asterisk {
    color: var(--red)
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
  appearance: textfield;
}

.guest-uploader-invalid-email {
    margin: 20px auto;
    max-width: 300px;
    border-radius: 6px;
    padding: 10px;
    color: var(--text-primary);
    background-color: var(--background-warning);
}