.site-selector-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.site-selector-select-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
}

.site-selector-label {
    padding-right: 20px;
}

.site-selector-input {
    width: 300px;
}

.site-selector-warning {
    margin: auto;
    max-width: 600px;
    border-radius: 6px;
    padding: 10px;
    color: var(--text-primary);
    background-color: var(--background-warning);
}

.site-selector-tooltip-icon {
    color: white;
    padding: 0px 10px;
    width: 16px;
}

.site-selector-tooltip-component {
    background-color: var(--background-warning);
    color: var(--text-primary);
}