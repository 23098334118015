.dicom-selector {
    padding-bottom: 20px;
}

.dicom-selector-select-folder {
    padding-bottom: 5px;
}

.dicom-selector-original-images-checkbox {
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.dicom-selector-loading {
    padding-top: 10px;
}