input[type=file] {
    display: none;
}

.custom-file-upload-primary {
    border: 1px;
    display: inline-block;
    border-radius: 8px;
    margin: 8px 0;
    padding: 6px 12px;
    cursor: pointer;
    font-size: 16px;
    background-color: var(--background-green);
    color: var(--text-black);
}

.custom-file-upload-primary-disabled {
    border: 1px;
    display: inline-block;
    border-radius: 8px;
    margin: 8px 0;
    padding: 6px 12px;
    font-size: 16px;
    background-color: var(--table-grey);
    color: var(--text-black);
}

.custom-file-upload-primary:hover {
    background-color: var(--background-green-highlight);
}