.user-input-layout {
    display: flex;
    flex-direction: column;
    align-items: left;
    margin: 0 auto;
    width: 800px;
}

.user-input-dropdown {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.user-input-dropdown-chevron {
    padding: 10px;
    width: 20px;
}

.user-input-dropdown-chevron:hover {
    color: var(--text-secondary)
}