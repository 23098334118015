/* The container */
.checkmark-container {
    display: block;
    position: relative;
    padding-left: 28px;
    padding-top: 10px;
    padding-bottom: 10px;
    height: 18px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-weight: bold;
}

.checkmark-container-disabled {
    cursor: default;
}

/* Hide the browser's default checkbox */
.checkmark-container input {
    position: absolute;
    opacity: 0;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 10;
    left: 0;
    height: 18px;
    width: 18px;
    background-color: var(--background-grey-blue-dark);
    border-radius: 4px;
}

/* On mouse-over, add a grey background color */
.checkmark-container:hover input ~ .checkmark {
    background-color: var(--background-grey-blue);
}

/* When the checkbox is checked, add a highlighted background */
.checkmark-container input:checked ~ .checkmark {
    background-color:  var(--background-green);
}
  
/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.checkmark-container input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.checkmark-container .checkmark:after {
    left: 6px;
    top: 2px;
    width: 3px;
    height: 8px;
    border: solid var(--background-grey-blue-dark);
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

/* When the checkbox is disabled, add a dark background */
.checkmark-disabled {
    background-color:  var(--background-grey-blue) !important;
}