.anonymization-table-text-input {
    outline: none;
    width: 100%;
    display: inline-block;
    background-color: black;
    color: var(--text-primary);
    border: 2px solid black;
    border-radius: 4px;
    box-sizing: border-box;
    font-size: 16px;
}

.anonymization-table-text-input::placeholder {
    color: var(--text-green);
}

.anonymization-table-text-input:focus::placeholder {
    color: transparent;
}

.anonymization-table-text-input:focus {
    width: 100%;
    display: inline-block;
    border: 2px solid var(--input-selected);
    border-radius: 4px;
    box-sizing: border-box;
    color: var(--text-primary)
}