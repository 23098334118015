.upload-issues-report {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.upload-issues-report-table {
    font-size: 16px;
    text-align: center;
    table-layout: fixed;
    border-collapse: collapse; 
}

.upload-issues-report-header-table {
    width: 1200px;
}

.upload-issues-report-issues-table {
    width: 1200px;
}

.upload-issues-report-table th {
    padding: 5px 5px;
}

.upload-issues-report-issues-table-count {
    width: 80px;
}

.upload-issues-report-table td {
    padding: 5px 5px;
    border: 1px solid var(--table-grey);
}

.upload-issues-report-csv-button {
    border: 1px;
    border-radius: 4px;
    width: 120px;
    padding: 5px 5px;
    cursor: pointer;
    font-size: 12px;
    background-color: var(--background-green);
    color: var(--text-black);
}

.upload-issues-report-csv-button:hover {
    background-color: var(--background-green-highlight);
}