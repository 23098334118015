.upload-status-item {
    display: flex;
    flex-direction: column;
    width: 400px;
    margin: auto;
}

.upload-status-name {
    color: var(--text-secondary);
    text-align: left;
    font-size: 16px;
}

.upload-status-progress {
    color: var(--text-primary);
    font-size: 16px;
    padding-left: 18px;
}

.MuiLinearProgress-barColorPrimary {
    color: red
}

.upload-footer-header {
    color: var(--text-primary)
}

.upload-footer-link {
    text-decoration: none;
    color: var(--text-green);
}

.upload-footer-link:hover {
    text-decoration: none;
    color: var(--text-green-highlight);
}

.upload-status-progres-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.upload-footer-failed-header {
    margin: auto;
    max-width: 600px;
    border-radius: 6px;
    padding: 10px;
    color: var(--text-primary);
    background-color: var(--background-warning);
}

.cancel-upload-button {
    border: 1px solid var(--background-green);
    display: inline-block;
    border-radius: 12px;
    width: 120px;
    margin-top: 50px;
    margin-left: 8px;
    margin-right: 8px;
    padding: 12px 12px;
    cursor: pointer;
    font-size: 12px;
    background-color: var(--background-grey-dark);
    color: var(--text-primary);
}

.cancel-upload-button:hover {
    background-color: var(--background-grey-blue-dark);
}