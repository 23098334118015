.patient-info-table-header {
    text-align: left;
}

.patient-info-table {
    font-size: 16px;
    text-align: left;
    table-layout: fixed;
    border-collapse: collapse;
    width: 764px;
}

.patient-info-table th {
    padding: 5px 5px;
}

.patient-info-table-cell {
    padding: 5px 5px;
    width: 250px;
    border: 1px solid var(--table-grey);
}

.patient-info-table-cell-fixed {
    color: var(--text-secondary)
}

.subject-id-warning {
    margin: 20px auto;
    max-width: 600px;
    border-radius: 6px;
    padding: 10px;
    color: var(--text-primary);
    background-color: var(--background-warning);
}