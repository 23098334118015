.next-prev-button {
    border: 1px;
    display: inline-block;
    border-radius: 12px;
    width: 120px;
    margin-top: 50px;
    margin-left: 8px;
    margin-right: 8px;
    padding: 12px 12px;
    cursor: pointer;
    font-size: 12px;
    background-color: var(--background-green);
    color: var(--text-black);
}

.next-prev-button:hover {
    background-color: var(--background-green-highlight);
}

.next-prev-button_disabled {
    cursor: default;
    background-color: var(--table-grey);
}

.next-prev-button_disabled:hover {
    background-color: var(--table-grey);
}

